<template>
  <div class="listContent">
    <div class="table-head">
      <el-form inline>
        <el-form-item label="问题：">
          <el-input
            v-model="searchForm.problemNoOrName"
            size="mini"
            placeholder="输入问题ID/内容"
          ></el-input>
        </el-form-item>

        <el-form-item label="问题类型：">
          <el-select v-model="searchForm.problemType" size="mini">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="问题题型：">
          <el-select v-model="searchForm.type" size="mini">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button size="mini" type="primary" @click="onSearch">查询</el-button>
          <el-button size="mini" icon="el-icon-refresh-left" @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>

      <div class="button">
        <el-button icon="el-icon-plus" size="mini" type="primary" @click="onAdd"
          >新增问题</el-button
        >

        <el-button
          :disabled="selectData.length ? false : true"
          icon="el-icon-delete"
          size="mini"
          @click="onDel()"
          >删除</el-button
        >
      </div>
    </div>

    <el-table
      v-loading="isLoading"
      :data="dataList"
      border
      style="margin-top: 10px"
      max-height="500px"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="40"> </el-table-column>

      <el-table-column prop="problemNo" label="问题ID"></el-table-column>
      <el-table-column prop="problemType" label="问题类型" width="80px">
        <template slot-scope="scope">
          {{ getProblemType(scope.row.problemType) }}
        </template>
      </el-table-column>

      <el-table-column prop="type" label="问题题型" width="100px">
        <template slot-scope="scope">
          {{ getType(scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column prop="relatedTag" label="关联客户字段/标签/字典分类" width="200px">
        <template slot-scope="scope">
          {{ scope.row.relatedUserFieldNote || scope.row.relatedTagValue || '/' }}
        </template>
      </el-table-column>

      <el-table-column prop="content" label="问题内容" min-width="200px"></el-table-column>
      <el-table-column prop="options" label="答案选项" width="300px" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.options ? scope.row.options : '/' }}
        </template>
      </el-table-column>
      <el-table-column prop="updateUserName" label="最后修改人"></el-table-column>
      <el-table-column prop="updateTime" label="最后修改时间" width="120px"></el-table-column>

      <el-table-column label="操作" width="150px" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="onEdit(scope.row)">编辑</el-button>
          <el-button type="text" @click="onDetail(scope.row)">详情</el-button>
          <el-button type="text" @click="onDel(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination
        :current-page="page.current"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="page.size"
        layout="  prev, pager, next, sizes, jumper"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getIssueList, deleteByIds } from '@/api/questionnaire.js'
import request from '@/mixins/request.js'
export default {
  mixins: [request],
  data() {
    return {
      selectData: [],
      options1: [
        // 问题类型
        {
          label: '自定义',
          value: 0,
        },
        {
          label: '数据字典',
          value: 1,
        },
        {
          label: '标签库',
          value: 2,
        },
        {
          label: '拍摄产品',
          value: 3,
        },
        {
          label: '拍摄人',
          value: 4,
        },
      ],
      options2: [
        // 问题题型
        {
          label: '单选题',
          value: 0,
        },
        {
          label: '多选题',
          value: 1,
        },
        {
          label: '日期编辑题',
          value: 2,
        },
        {
          label: '填空题',
          value: 3,
        },
        {
          label: '图片编辑题',
          value: 4,
        },
      ],
    }
  },
  mounted() {
    this.getData(getIssueList)
  },
  methods: {
    handleSelectionChange(val) {
      this.selectData = val
    },
    getProblemType(problemType) {
      if (problemType === null || problemType === undefined) return '/'
      const data = this.options1.find((e) => e.value === problemType)
      return data?.label || '/'
    },
    getType(type) {
      if (type === null || type === undefined) return '/'
      const data = this.options2.find((e) => e.value === type)
      return data?.label || '/'
    },
    onAdd() {
      const routerUrl = this.$router.resolve({
        name: 'addOrEditIssue',
        query: { listType: 'issue' },
      })
      window.open(routerUrl.href, '_blank')
    },
    onEdit(row) {
      const routerUrl = this.$router.resolve({
        name: 'addOrEditIssue',
        query: { listType: 'issue', id: row.id },
      })
      window.open(routerUrl.href, '_blank')
    },
    onDetail(row) {
      const routerUrl = this.$router.resolve({
        name: 'addOrEditIssue',
        query: { listType: 'issue', id: row.id, disabled: true },
      })
      window.open(routerUrl.href, '_blank')
    },
    async onDel(id) {
      let delArr = []
      if (id) {
        delArr.push(id)
      } else {
        delArr = this.selectData.map((e) => e.id)
      }
      await this.$confirm('确认删除已选中的问题吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
      try {
        await deleteByIds({
          data: delArr,
        })
        this.$message({
          type: 'success',
          message: '删除成功!',
        })
        this.getData()
      } catch (error) {
        console.log('进入了', error)
        // this.$message({
        //   type: "error",
        //   message: error.description || "删除失败！",
        // });
      }
    },
    beforeSearch(search) {
      if (search.startTime?.length) {
        search.endTime = search.startTime[1]
        search.startTime = search.startTime[0]
      } else {
        search.startTime = ''
      }
      return search
    },
  },
}
</script>

<style lang="scss" scoped>
.listContent {
  .table-head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    ::v-deep .el-form-item {
      margin-right: 20px !important;
    }
  }
}
.button {
  padding-top: 6px;
  width: 182px;
}
</style>
