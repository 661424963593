<template>
  <div class="listContent">
    <div class="table-head">
      <el-form inline>
        <el-form-item label="模板：">
          <el-input
            v-model="searchForm.templateNoOrName"
            size="mini"
            placeholder="输入模板ID/名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="最后修改时间">
          <el-date-picker
            v-model="searchForm.startTime"
            size="mini"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button size="mini" type="primary" @click="onSearch"
            >查询</el-button
          >
          <el-button size="mini" icon="el-icon-refresh-left" @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>

      <div style="padding-top: 6px">
        <el-button icon="el-icon-plus" size="mini" type="primary" @click="add"
          >新增模板</el-button
        >

        <el-button
          icon="el-icon-delete"
          size="mini"
          :disabled="selectData.length == 0"
          @click="deleteTemplates(null, $event)"
          >删除</el-button
        >
      </div>
    </div>

    <el-table
      v-loading="isLoading"
      :data="dataList"
      max-height="500px"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="40"> </el-table-column>

      <el-table-column
        prop="templateNo"
        label="模板ID"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="templateName"
        label="模板名称"
        width="200"
      ></el-table-column>
      <el-table-column prop="openingWords" label="开头语" min-width="300px">
      </el-table-column>

      <el-table-column
        prop="updateUserName"
        label="最后修改人"
        width="100"
      ></el-table-column>

      <el-table-column
        prop="updateTime"
        label="最后修改时间"
        width="160"
      ></el-table-column>

      <el-table-column label="操作" width="250" fixed="right">
        <template slot-scope="scope">
          <div class="handle-area">
            <span class="handle-item" @click="gotoEdit(scope.row)">编辑</span>
            <span class="handle-item" @click="gotoDetail(scope.row)">详情</span>

            <span
              class="handle-item red-item"
              @click="deleteTemplates(scope.row)"
              >删除</span
            >
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination
        :current-page="page.current"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="page.size"
        layout="  prev, pager, next, sizes, jumper"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getTemplateList, deleteTemplate } from "@/api/questionnaire.js";
import request from "@/mixins/request.js";
export default {
  mixins: [request],
  data() {
    return {
      selectData: [],
      // searchForm: {
      //   templateNoOrName: "",
      //   startTime: [],
      //   endTime: "",
      // },
    };
  },
  mounted() {
    this.getData(getTemplateList);
  },
  methods: {
    beforeSearch(search) {
      if (search.startTime?.length) {
        search.endTime = search.startTime[1];
        search.startTime = search.startTime[0];
      } else {
        search.startTime = "";
      }
      return search;
    },
    handleSelectionChange(val) {
      this.selectData = val;
    },
    gotoDetail(row) {
      const newRoute = this.$router.resolve({
        path: "/questionnaireManage/templateDetail",
        query: { id: row.id },
      });

      window.open(newRoute.href);
    },
    add() {
      const newRoute = this.$router.resolve({
        path: "/questionnaireManage/addOrEditTemplate",
      });
      window.open(newRoute.href);
    },
    gotoEdit(row) {
      const newRoute = this.$router.resolve({
        path: "/questionnaireManage/addOrEditTemplate",
        query: { id: row.id },
      });

      window.open(newRoute.href);
    },
    async deleteTemplates(row, e) {
      let reqObj = [];
      if (!row) {
        reqObj = {
          data: this.selectData.map((el) => el.id),
        };
      } else {
        reqObj = {
          data: [],
        };
        reqObj.data.push(String(row.id));
      }
      try {
        await this.$confirm("确认删除已选中的模板吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        const res = await deleteTemplate(reqObj);
        if (res.success) {
          this.$message.success("删除成功");
          this.getData(getTemplateList);
        }
      } catch (e) {}
    },
    reset() {
      this.searchForm = {};
      this.getData(getTemplateList);
    },
  },
};
</script>

<style lang="scss" scoped>
.listContent {
  .table-head {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;

    ::v-deep .el-form-item {
      margin-right: 20px !important;
    }
  }

  .handle-area {
    white-space: nowrap;

    .handle-item {
      color: #2861e2;
      padding-right: 10px;
      border-right: 1px solid #e0e0e0;
      margin-left: 10px;
      cursor: pointer;
    }

    .red-item {
      color: #e86247;
    }

    .handle-item:last-of-type {
      border-right: unset;
    }
  }
}
</style>
