<template>
  <div class="questionnaireManage">
    <el-tabs id="questionnaireTabs" v-model="listType" @tab-click="handleClick">
      <el-tab-pane label="问卷库" name="questionnaire">
        <questionnaire ref="questionnaire" />
      </el-tab-pane>
      <el-tab-pane label="模板库" name="template">
        <questionnaireTemplate ref="template" />
      </el-tab-pane>
      <el-tab-pane label="问题库" name="issue">
        <issue ref="issue" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import questionnaire from "@/views/questionnaireManage/components/questionnaire/index.vue";
import questionnaireTemplate from "@/views/questionnaireManage/components/template/index.vue";
import issue from "@/views/questionnaireManage/components/issue/index.vue";
export default {
  components: { questionnaire, questionnaireTemplate, issue },
  data() {
    return {
      listType: "questionnaire",
    };
  },
  created() {
    if (this.$route.query.listType) {
      this.listType = this.$route.query.listType;
    }
  },
  mounted() {
    window.addEventListener("keydown", this.keydown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.keydown);
  },
  methods: {
    handleClick(e) {
      this.$refs[this.listType].getData();
    },
    keydown(e) {
      if (e.key === "Enter") this.$refs[this.listType].getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.questionnaireManage {
  padding: 24px 20px;
  background: #fff;
}
</style>
