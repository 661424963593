<template>
  <div class="listContent">
    <div class="table-head">
      <el-form inline>
        <el-form-item label="问卷：">
          <el-input
            v-model="searchForm.questionnaireNoOrName"
            size="mini"
            placeholder="请输入问卷ID/名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="最后修改时间">
          <el-date-picker
            v-model="searchForm.startTime"
            size="mini"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button size="mini" type="primary" @click="onSearch">查询</el-button>
          <el-button size="mini" icon="el-icon-refresh-left" @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>

      <div style="padding-top: 6px">
        <el-button icon="el-icon-plus" size="mini" type="primary" @click="addQuestionnaire"
          >新增问卷</el-button
        >
      </div>
    </div>

    <el-table v-loading="isLoading" :data="dataList" border max-height="500px">
      <el-table-column prop="questionnaireNo" label="问卷ID" width="120"></el-table-column>
      <el-table-column prop="questionnaireName" label="问卷名称" width="140px"></el-table-column>

      <el-table-column prop="numberOfSubmit" label="已发送份数" width="100">
        <template slot-scope="scope">
          {{ scope.row.numberOfSend || '/' }}
        </template>
      </el-table-column>
      <el-table-column prop="numberOfSubmit" label="已提交份数" width="100">
        <template slot-scope="scope">
          {{ scope.row.numberOfSubmit || '/' }}
        </template>
      </el-table-column>

      <el-table-column label="绑定单品" min-width="200px">
        <template slot-scope="scope">
          <el-tooltip placement="bottom">
            <div slot="content" style="display: flex">
              <span style="display: flex; flex-direction: column">
                <div
                  v-for="(item, index) in scope.row.products"
                  :key="item.tyProductId"
                  :style="index % 2 == 0 ? '' : 'display:none'"
                >
                  {{ item.tyProductCode }}{{ item.tyProductName }}；
                </div>
              </span>
              <span style="display: flex; flex-direction: column">
                <div
                  v-for="(item, index) in scope.row.products"
                  :key="index"
                  :style="index % 2 == 1 ? '' : 'display:none'"
                >
                  {{ item.tyProductCode }}{{ item.tyProductName }}；
                </div>
              </span>
            </div>
            <span class="product-text">{{ scope.row.products | productNameFilters }}</span>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column prop="updateUserName" label="最后修改人" width="100"></el-table-column>

      <el-table-column prop="updateTime" label="最后修改时间" width="110px"></el-table-column>

      <el-table-column label="已完成" width="80" fixed="right">
        <template slot-scope="scope">
          <el-tag :type="scope.row.isSave ? 'success' : 'danger'">{{
            scope.row.isSave ? '已完成' : '草稿'
          }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="250" fixed="right">
        <template slot-scope="scope">
          <div class="handle-area">
            <span class="handle-item" @click="openSaveTemplate(scope.row)">保存模板</span>
            <span v-if="scope.row.isSave == 1" class="handle-item" @click="gotoDetail(scope.row)"
              >详情</span
            >
            <span v-if="scope.row.isSave == 0" class="handle-item" @click="gotoEdit(scope.row)"
              >编辑</span
            >
            <span
              v-if="scope.row.isSave && scope.row.isHavePhotographPeople !== 1"
              class="handle-item"
              @click="copyLink(scope.row)"
            >
              复制链接
            </span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-pagination
        :current-page="page.current"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="page.size"
        layout="  prev, pager, next, sizes, jumper"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <el-dialog :visible.sync="templatedialog" width="40%" title="保存模板">
      <div style="display: flex; justify-content: center; align-items: center">
        <span><span style="color: red">*</span> 模板名称：</span>
        <el-input
          v-model="templateName"
          style="width: 300px"
          placeholder="请填写模板名称"
          maxlength="10"
          show-word-limit
        ></el-input>
      </div>

      <span slot="footer">
        <el-button
          size="mini"
          @click="
            templatedialog = false
            templateName = ''
          "
          >取 消</el-button
        >
        <el-button type="primary" size="mini" @click="saveTemplate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getQuestionnaireList, saveTemplate } from '@/api/questionnaire.js'
import request from '@/mixins/request.js'
export default {
  filters: {
    productNameFilters: function (value) {
      if (!value) return ''

      const newArr = value.map((el) => el.tyProductCode + el.tyProductName).join(';')

      return newArr
    },
  },
  mixins: [request],
  props: {
    listType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        questionnaireNoOrName: '',
        startTime: [],
        endTime: '',
      },
      templatedialog: false,
      currentRow: {},
      templateName: '',
    }
  },
  mounted() {
    this.getData(getQuestionnaireList)
  },
  methods: {
    beforeSearch(search) {
      if (search.startTime?.length) {
        search.endTime = search.startTime[1]
        search.startTime = search.startTime[0]
      } else {
        search.startTime = ''
      }
      return search
    },
    addQuestionnaire() {
      const newRoute = this.$router.resolve({
        path: '/questionnaireManage/addQuestionnaire',
      })

      window.open(newRoute.href)
    },
    gotoDetail(row) {
      const newRoute = this.$router.resolve({
        path: '/questionnaireManage/questionnaireDetail',
        query: { id: row.id },
      })

      window.open(newRoute.href)
    },
    gotoEdit(row) {
      const newRoute = this.$router.resolve({
        path: '/questionnaireManage/addQuestionnaire',
        query: { id: row.id },
      })
      console.log(newRoute)

      window.open(newRoute.href)
    },
    openSaveTemplate(row) {
      this.templatedialog = true
      this.currentRow = row
    },
    async saveTemplate() {
      if (!this.templateName) {
        this.$message.error('请填写模板名称')
        return
      }

      const reqObj = {
        data: {
          templateName: this.templateName,
          openingWords: this.currentRow.openingWords,
          problems: this.currentRow.problemIds.map((el) => ({ id: el })),
        },
      }

      const res = await saveTemplate(reqObj)
      if (res.success) {
        this.$message.success('保存成功')
        this.templateName = ''
        this.templatedialog = false
      }
    },
    copyLink(row) {
      console.log(process.env.NODE_ENV, process.env.NODE_ENV === ('development' || 'staging'))
      this.$copyText(
        process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging'
          ? `http://test-activity-h5.zzgy333.com/?id=${row.id}`
          : `https://activity-h5.zzgy333.com/?id=${row.id}`,
      )
        .then((e) => {
          this.$message.success('复制成功')
        })
        .catch(() => {
          this.$message.error('复制失败')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.listContent {
  .table-head {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;

    ::v-deep .el-form-item {
      margin-right: 20px !important;
    }
  }

  .handle-area {
    white-space: nowrap;

    .handle-item {
      color: #2861e2;
      padding-right: 10px;
      border-right: 1px solid #e0e0e0;
      margin-left: 10px;
      cursor: pointer;
    }

    .handle-item:last-of-type {
      border-right: unset;
    }
  }

  .product-text {
    // overflow: hidden; //溢出内容隐藏
    overflow: hidden;
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 3; //行数
    -webkit-box-orient: vertical; //盒子中内容竖直排列
  }
}
</style>
